import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import tw from 'twin.macro';
import Layout from '../components/Layout';
import Content from '../components/Content';
import Seo from '../components/Seo';

export default ({ data: { about, avatar } }) => (
  <Layout>
    <Seo
      title="Über uns"
      description="Karsten ist bereits seit 2007 in der Reisebranche aktiv. Ursprünglich wollte er die Menschen dazu motivieren mehr Sport zu machen und Sportlern attraktive Reiseziele anzubieten. Mittlerweile organisiert er selber Reisen in der ganzen Welt und das nicht nur für Sportler & Aktive..."
    />
    <div
      css={tw`flex flex-col items-center px-8 mx-auto sm:max-w-4xl lg:max-w-7xl`}
    >
      <Img
        fluid={avatar.childImageSharp.fluid}
        css={tw`w-48 h-48 mt-12 rounded-full`}
      />
      <div>
        <Content content={about._rawBody} />
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    about: sanitySite(slug: { current: { eq: "ueber-uns" } }) {
      title
      _rawBody
    }
    avatar: file(relativePath: { eq: "index/user.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
